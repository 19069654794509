import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'common/Banner';
import { toBoolean } from 'utils/stringUtils/stringUtils';

const VideoNaturalBanner = ({ lang: pageLang }) => {
  const { allVideoNaturalBanner }: HomepageTypes.HomepageType = useStaticQuery(graphql`
    {
      allVideoNaturalBanner {
        nodes {
          lang
          content
          bannerVariant
          imageAsBackgroundAlt
          withWrapper
          withVideo {
            title
            autoplay
            link
            image {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
          }
        }
      }
    }
  `);

  const {
    content,
    bannerVariant,
    button,
    imageAsBackgroundAlt,
    withWrapper,
    withVideo,
  } = allVideoNaturalBanner.nodes.filter(({ lang }) => lang === pageLang)[0];

  return (
    <Banner
      {...{
        content,
        button,
        bannerVariant,
        imageAsBackgroundAlt,
        isWithWrapper: toBoolean(withWrapper),
        withVideo,
      }}
    />
  );
};

export default VideoNaturalBanner;
